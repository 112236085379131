<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <v-container>
      <h2 class="text-center">JOIN OUR BOOKCLUB</h2>

      <v-row>
        <v-col cols="10" sm="8" md="8" class="mx-auto mt-5">
          <v-form ref="dform" lazy-validation @submit.prevent>
            <v-text-field
              v-model.trim="dform.fullname"
              :rules="nameRules"
              label="Name"
              counter="50"
              outlined
              required
              rounded
            ></v-text-field>

            <v-text-field
              v-model.trim="dform.email"
              outlined
              :rules="emailRules"
              label="E-mail"
              required
              rounded
            ></v-text-field>

            <v-text-field
              v-model.trim="dform.phoneno"
              :rules="phoneRules"
              outlined
              counter="15"
              label="Phone Number"
              required
              rounded
            >
            </v-text-field>

            <v-btn
              color="primary"
              class="mr-4 button"
              @click="submitForm"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :color="snackColor">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn dark small v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import BreadCrumb from "@/components/BreadCrumb.vue";
import { snackMixin } from "@/mixins";

export default {
  name: "Contact",
  components: { BreadCrumb },
  mixins: [snackMixin],
  data() {
    return {
      dform: this.initializeForm(),
      nameRules: [
        (v) => !!v || "Full Name is required",
        (v) =>
          (v && v.length <= 50) || "Full Name must be less than 50 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) =>
          (v && v.length <= 15) ||
          "Phone number must be less than 15 characters",
      ],

      items: [
        {
          text: "Home",
          disabled: false,
          link: "Home",
        },
        {
          text: "Bookstore",
          disabled: false,
          link: "Bookstore",
        },
        {
          text: "Bookclub",
          disabled: true,
        },
      ],
    };
  },

  methods: {
    submitForm() {
      if (!this.$refs.dform.validate()) {
        return;
      }

      this.loading = true;
      apiClient
        .post("/bookclub_members", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.dform = this.initializeForm();
            this.displayMsg(
              "Thanks! Registration successful, We will get back to you"
            );
            this.$refs.dform.resetValidation();
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((error) => this.displayMsg(error.message, "error"));
    },

    initializeForm: () => ({
      id: "",
      fullname: "",
      email: "",
      phoneno: "",
    }),
  },
};
</script>
